import React, {Component, Fragment} from "react";
import CategoryForm from "./DataTable/CategoryForm";

import {__} from "../layouts/utilities/i18n";
import {compose, graphql, Mutation, Query, withApollo} from "react-apollo";
import {
	getQueryArgs,
	querySingleName,
	querySingle,
	getChangeName,
	getInputTypeName,
	getMutationArgs, mutationEdit
} from "../layouts/schema";
import Loading from "../layouts/utilities/Loading";
import {Button, ButtonGroup, Dialog, Intent, Callout} from "@blueprintjs/core";
import {withRouter} from "react-router";
import {checkRequired, getFields, getInput} from "../layouts/schema/ecosystem";
import {AppToaster} from "../layouts/utilities/blueUtils";
import {geoPosition} from "../layouts/map";


class SCNewPlaceFormState extends Component
{

	onCreate = (evt, m_change) =>
	{
		evt.preventDefault();
		let input = getInput(this.state, "PublicPlace");
	
		if(input.types)
		{
			input.types_ids = input.types.map(e => e._id);
			delete input.types
		} 
		if( !input.contact_email )
		{
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				className: "p-5",
				message: __("Введите корректный адрес электронной почты")
			});
			return;
		}

		m_change({
			variables:
				{
					"input": input
				},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data["changePublicPlace"];
				if(respData)
				{
					AppToaster.show({
						intent: Intent.SUCCESS,
						icon: "tick",
						className: "p-5",
						message: __("Ваша заявка принята")
					});
					this.props.history.push( "/" );
				}else{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						className: "p-5",
						message: __("Что-то пошло не так.")
					});
				}


			}
		});

	}
	onCancelCreate = () =>
	{
		this.props.history.push( "/" );
	}

	newPlaceForm = (m_change) =>{

		const default_coords = geoPosition();
		return <div className="layout-state">
			<div className="layout-state-head justify-content-center">
				{__("Добавить новую организацию или услугу")}
			</div>
			<div className="d-flex justify-content-center">
				<div style={{maxWidth:800}}>
					<Callout intent={Intent.PRIMARY} className="mb-5 mt-3">
						{__("Мы рады, что вы решили разместить себя в нашем каталоге. \n" +
							"Поверьте - это отличная идея! Вам понравится, и мы подружимся. Давайте вместе делать большое дело развития в мире идей свободного образования. Мы размещаем всех, кто имеет отношение к альтернативному образованию. Но мы и не хотим, чтобы здесь было много организаций хороших, но далёких от наших идей. Поэтому некоторый фильтр есть. Извините нас, если мы кому-то откажем. Итак, начинаем заполнять. У вас всё получится!")}
					</Callout>
					<Callout intent={Intent.PRIMARY} className="mb-5 mt-3">
						Отметьте себя на карте. У нас есть два способа:
						<ol>
							<li>Просто наберите ваш адрес в строке под картой и машина сама всё найдёт.</li>
							<li>Найдите ваше место на карте и смело щелкните по нему. Машина опять всё делает за вас. Она умная.</li>
						</ol>
					</Callout>
					<p class="form-important">
						Внимание! Все поля, кроме фото, обязательны к заполнению! Необходимо оставить хотя бы одну ссылку на сайт или на соцсеть.
					</p>
					<CategoryForm
						ID={null}
						data={ {"title":"", "geo": default_coords} }
						data_type={ "PublicPlace" }
						on={this.onChange}
						onChange={this.onChange}
						// onSave={(state, _id) => this.onSave( state, _id)}
						onDelete={this.onDelete}
						onClose={ this.onClose }
						vertical={true}
						isHiddenSave={true}
						isHiddenClose={true}
						isHiddenDelete={true}
						isOpen={ true }
					/>
					<Callout intent={Intent.PRIMARY} className="mb-5 mt-3">
						{__("После того, как вы отправите на модерацию ваш проект, вы получите на ваш адрес письмо с подтверждением размещения или отказа в размещении вашего проекта в каталоге.\n" +
							"\n" +
							"После размещения вашего проекта в каталоге, вы должны будете подтверждать ваше участие в нем каждые пол года. Это не сложно, и помогает нам поддерживать актуальность информации.\n")}
					</Callout>


					<ButtonGroup>
						<Button
							icon="plus"
							text={__("Отправить на модерацию")}
							onClick={(evt) => this.onCreate(evt, m_change)}
							disabled={!checkRequired("PublicPlace", this.state )}
						/>
						{/*<Button*/}
						{/*	icon="minus"*/}
						{/*	text={__("Я передумал.Вернуться на главную страницу")}*/}
						{/*	onClick={this.onCancelCreate}*/}
						{/*/>*/}
					</ButtonGroup>
				</div>
			</div>
		</div>
	}

	render()
	{
		const mutation_name = getChangeName( "PublicPlace" );
		const input_type_name = getInputTypeName( "PublicPlace" );
		const mutation_args = getMutationArgs( "PublicPlace" );
		const change_mutation = mutationEdit( "PublicPlace", mutation_name, input_type_name, mutation_args );


		return <Mutation mutation={change_mutation}>
			{( m_change, { data } ) => this.newPlaceForm(m_change)}
		</Mutation>



	}

	onDelete = () =>
	{
		
	}
	onClose =() =>
	{
		
	}
	
	onChange=(field, value, id) =>
	{
		let state = {...this.state};
		state[value] = field;
		this.setState( state, function()
		{
			console.log( this.state );
			//this.props.on(field, value);
		});
	}
}

export default compose(
	withApollo,
	withRouter
)(SCNewPlaceFormState);