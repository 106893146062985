import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from "jquery";
import LazyLoading from "../../layouts/utilities/LazyLoading";
import {upload_url} from "../../layouts/config";
import {__} from "../../layouts/utilities/i18n";
import { FaVk, FaFacebook, FaTelegram, FaGlobe } from "react-icons/fa";

class CatalogResult extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			isOpen:false,
			height: 110
		};
	}
	render () 
	{

		const default_icon = "/assets/img/sc/dpo.png";
		const logo = this.props.file ? upload_url() +  this.props.file : default_icon;
		const _tags = this.props.types ? this.props.types  : [];
		const tags = _tags.map((e, i) =>
		{
			return <div 
				_id={e._id}
				className={"search-result-tag d-inline rounded-sm py-1 px-2 mr-1 mb-1"} 
				style={{ backgroundColor: e.color }}
				key={i}
			>
				{e.title}
			</div>
		})
		const result = this;
		return <LazyLoading
			animationType="fadeInUp"
			content = {
				<div 
					id={ "search_resault_" +this.props._id }
					className="row m-0 search-result border d-flex flex-row rounded-0 py-3 px-3 mt-2"
					
				>
					<div className="col-sm-auto pl-0 mb-1 mb-sm-0 pr-3">
						<img className="search-result-image" src={ logo }  />
					</div>
					<div className="col d-flex px-0 flex-wrap flex-column"> 
						<div className="search-result-title text-break d-flex align-self-start">
							<Link 
								to={'/?id=' + this.props._id} 
							>
								{this.props.title}
							</Link>
						</div>
						<div className="search-result-tags d-flex align-content-start flex-wrap">					
							{tags}
						</div>

						<div className="search-result-adress text-break">
							{this.props.address}
							<span 
								className="text-decoration-underline ml-3" 
								onClick={ this.showMap }
							>
								{__("Показать на карте")}
							</span>
						</div> 
						{(this.props.vk || this.props.telegram || this.props.facebook || this.props.site) ?
						<div className="pb-2 pt-4 soc-links">
						{this.props.vk ? <a href={this.props.vk} target="_blank">
								<FaVk /></a> : null}
						{this.props.telegram ? <a href={this.props.telegram} target="_blank">
							<FaTelegram /></a> : null}
						{this.props.facebook ? <a href={this.props.facebook} target="_blank">
							<FaFacebook /></a> : null}
						{this.props.site ? <a href={this.props.site} target="_blank">
							<FaGlobe /></a> : null}
						</div> : null}
						<div 
							className="sc-content pb-2 pt-4 school-description" 
							dangerouslySetInnerHTML={{__html:this.props.description}}
							onClick={ this.onToggled }
							style={{height : this.state.height}}
						/>
					</div>
				</div>
			}
			/>
		}
		showMap = () =>
		{
			this.props.onShowMap(this.props);
			window.scrollTo(0, document.getElementsByClassName("bp3-tab-list")[0].getBoundingClientRect().top + document.documentElement.scrollTop);
		}
		onToggled = evt =>
		{
			let total;
			if(this.state.isOpen)
			{
				total = 110;
			}
			else
			{
				const par 	=  $(evt.currentTarget).parents( ".search-result" ) ;
				total 		= $(par).find( ".sc-content" ).css({ "height" : "auto" }).height() + 60;
				$(par).find( ".sc-content" ).css({ "height" : 110 }).height( 110 );
			}
			//console.log( $(par).find( ".sc-content").css({"height" : "auto"}).height() );
			this.setState({ isOpen : !this.state.isOpen, height: total })
		}
	}

export default CatalogResult;